import React from "react";

import SEO from "../components/seo";
import "../styles/common/common.scss";
// line-awesome
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import { ISEOProps } from "../types/common";

import Main from "../components/_pages/Main";
import Footer from "../components/Footer";

const seoData: ISEOProps = {};

function Container() {
  return (
    <div>
      <SEO {...seoData} />
      <Main />
      <Footer />
    </div>
  );
}

export default Container;
