import React from "react";
import "./index.scss";
import Image from "../../image";

export default function Container() {
  return (
    <section className="container main">
      <h1 className="title">
        개발자 쿠스가 만드는 <br />
        <span className="strong">타르트</span> 같은 프로그램들 모음
      </h1>

      <Image imgName="qustart-logo.png" className="logo-img" />

      <div className="message-wrapper">
        <div className="message">
          <div className="title">
            <span>QU</span>
            <span className="strong">START</span>
          </div>
          <div className="desc">
            나에게는 즐거운 <span className="strong">시작</span>이 되고
          </div>
        </div>
        <div className="message">
          <div className="title">
            <span>QUS</span>
            <span className="strong">TART</span>
          </div>
          <div className="desc">
            사람들에게는 먹기 편하고 맛있는{" "}
            <span className="strong">타르트</span>처럼 {/* <br /> */}
            <br className="invisible-desktop" />
            느껴졌으면 하는 것들을 만듭니다.
          </div>
        </div>
      </div>

      <div className="tart-shelf-wrapper">
        <div className="tart-shelf">
          <div className="tart-container">
            <a href="/timechef">
              <div className="tart">
                <Image imgName="timechef-logo.png" className="tart-topping" />
                <Image imgName="qustart-base.png" className="tart-base" />
              </div>
            </a>
            <div className="tart">
              <div className="tart-topping">?</div>
              <Image imgName="qustart-base.png" className="tart-base" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
